const eventDates = document.querySelectorAll('.event-date[data-timestamp]');
for (let i = 0; i < eventDates.length; i++) {
    const eventDate = eventDates[i];
    const timestamp = Number.parseInt(eventDate.dataset.timestamp, 10);
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    const time = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

    eventDate.querySelector('.day').innerHTML = day;
    eventDate.querySelector('.month').innerHTML = month + '.';
    eventDate.querySelector('.year').innerHTML = year;
    eventDate.querySelector('.start-time').innerHTML = time;
}

const eventTimes = document.querySelectorAll('.event-time[data-timestamp]');
for (let i = 0; i < eventTimes.length; i++) {
    const eventTime = eventTimes[i];
    const timestamp = Number.parseInt(eventTime.dataset.timestamp, 10);
    const date = new Date(timestamp * 1000);
    const dateString = date.toLocaleString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });
    const timeString = date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });

    eventTime.querySelector('.event-date').innerHTML = dateString;
    eventTime.querySelector('.event-time > span').innerHTML = timeString;
}

const checkLive = function checkLive(childEvent, date) {
    const now = new Date();
    const tenAgo = new Date(now.getTime() - (10 * 60 * 1000));
    const fifteenAgo = new Date(now.getTime() - (15 * 60 * 1000));
    const live = childEvent.querySelector('.live');
    if (date < now && date > fifteenAgo) {
        live.classList.remove('hidden');
    } else {
        live.classList.add('hidden');
    }
};

const childEvents = document.querySelectorAll('.child-event');
for (let i = 0; i < childEvents.length; i++) {
    const childEvent = childEvents[i];
    const childEventTime = childEvent.querySelector('.time');
    const timestamp = Number.parseInt(childEventTime.dataset.timestamp, 10);
    const date = new Date(timestamp * 1000);

    childEventTime.innerHTML = date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });

    if (childEvent.querySelector('.live')) {
        checkLive(childEvent, date);

        setInterval(function() {
            checkLive(childEvent, date);
        }, 15 * 1000); // checks every 15 seconds
    }
}

const padTime = function padTime(time) {
    return ('0' + time).slice(-2);
}

const makeTimeString = function makeTimeString(timestamp) {
    const seconds = timestamp % 60;
    const minutes = Math.floor(timestamp / 60) % 60;
    const hours = Math.floor(timestamp / (60 * 60)) % 24;
    const days = Math.floor(timestamp / (60 * 60 * 24));
    return days + ':' + padTime(hours) + ':' + padTime(minutes) + ':' + padTime(seconds);
}

const startCountdown = function startCountdown(countdown) {
    const currentDate = new Date();
    const currentTime = currentDate.getTime();
    const eventTime = Number.parseInt(countdown.dataset.timestamp, 10) * 1000;
    let timeDiff = Math.round((eventTime - currentTime) / 1000);
    const loop = setInterval(function() {
        countdown.textContent = makeTimeString(timeDiff);
        timeDiff -= 1;
    }, 1000);
}

const countdown = document.querySelector('.event-countdown');
if (countdown) {
    startCountdown(countdown);
}
